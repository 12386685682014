<template>
  <div>
    <user-list-add-new
      :user-data.sync="userData"
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :is-edit.sync="isEdit"
    />

    <!-- PERMISSION TABLE -->
    <b-card no-body class="border mt-1">
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <span class="align-middle ml-50">Skills</span>
        </b-card-title>
        <b-button variant="primary" @click="addData">
          <span class="text-nowrap">Add Skills</span>
        </b-button>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        hover
        :items="$store.state.candidate.skills"
        :fields="fields"
      >
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
                <b-button  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-primary"
          class="btn-icon ml-0"
             @click="editData(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle "></span>
            </b-button>

            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-primary"
          class="btn-icon ml-0"
           v-b-modal="modalId(data.item.id)" >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle "></span>
            </b-button>
              <b-modal :id="'modal' + data.item.id"  title="Confirm Delete?">
                <div class="alert ">
                  Are you sure to remove <b>{{data.item.skillSetName}}</b>?
                </div>
                <div slot="modal-footer">
                  <p>
                    <b-button size="md" class="float-left mr-1" variant="secondary" @click="$bvModal.hide('modal' + data.item.id)">
                      <i class="fas fa-times"></i> No
                    </b-button> 
                    <b-button size="md" class="float-right" variant="danger" @click="deleteMess(data.item.id)">
                      <i class="fas fa-check"></i> Yes
                    </b-button>
                  </p>
                </div>
              </b-modal>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormRadioGroup,
  BFormRadio,
  BIcon,
  VBModal,
} from "bootstrap-vue";
import userStoreModule from "../userStoreModule";
import vSelect from "vue-select";
import { ref, onUnmounted } from "@vue/composition-api";
import UserViewUserTimelineCard from "../users-view/UserViewUserTimelineCard.vue";
import UserListAddNew from "./UserListAddNew.vue";
import router from "@/router";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Vue } from "vue-property-decorator";
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    UserListAddNew,
    ToastificationContent,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    vSelect,
    UserViewUserTimelineCard,
    BIcon,
    VBModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: "skillSetName", label: "SKILL NAME", sortable: true },
        { key: "displayOrder", label: "DISPLAY ORDER", sortable: true },
        { key: "actions", sortable: false },
      ],
      item:{},
      modalShow: false
    };
  },
  methods: {
    modalId(id){
    return 'modal' + id
    },
    deleteMess(id){
     this.$bvModal.hide('modal' + id)
     this.deleteData(id);
    }
   
  },
  mounted() {
    this.method();
  },
  created:function () {
       this.getData()
  },

  setup(props) {


    const userData = ref({
      skillSetName: "",
      displayOrder: 0,
    });
    
    const isEdit = ref(false);
    const isAddNewUserSidebarActive = ref(false);
    const skillsData = ref(store.state.candidate?.skills | []);
    const USER_APP_STORE_MODULE_NAME = "candidate";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const getData = () => {
      store
        .dispatch("candidate/fetchSkills", {})
        .then((response) => {
          skillsData.value = store.state.candidate.skills;
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(error.response.data.errors)) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: "error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text:value,
            },
          });
          }
          if (error.response.status === 404) {
            skillsData.value = [];
          }
        });
    };

    const deleteData = (id) => {

      store
        .dispatch("candidate/SkillDelete", {
          id: id,
        })
        .then((response) => {
           getData()
           Vue.$toast({
            component: ToastificationContent,
            props: {
              title: "Deleted",
                icon: "AlertTriangleIcon",
                variant: "success",
            },
          });
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(error.response.data.errors)) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: "error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text:value,
            },
          });
          }
        });
    };
    const addData= (item) => {
    isEdit.value=false;
    isAddNewUserSidebarActive.value=true;
   }
   const editData= (item) => {
         userData.value={
           id:item.id,
      skillSetName: item.skillSetName,
      displayOrder: item.displayOrder,
    };
    isEdit.value=true;
    isAddNewUserSidebarActive.value=true;
   }

    return {
      skillsData,
      isAddNewUserSidebarActive,
      deleteData,
      getData,
      editData,
      userData,
      isEdit,
      addData,
     
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.w-module {
  width: 100vw !important;
}
.w-module-n {
  width: 100% !important;
}
</style>
