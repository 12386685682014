<template>
  <b-modal
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    hide-header
    hide-footer
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Add New Skills</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="firstName"
            rules="required"
          >
            <b-form-group label="Skills Name" label-for="full-name">
              <b-form-input
                id="full-name"
                v-model="userData.skillSetName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Order"
            rules="required|alpha-num"
          >
            <b-form-group label="Order" label-for="username">
              <b-form-input
                id="username"
                v-model="userData.displayOrder"
                type="number"
                :state="getValidationState(validationContext)"
                trim
              />
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ isEdit ? "Edit" : "Add" }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
//import countries from '@/@fake-db/data/other/countries'
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from "@/router";

export default {
  components: {
    BSidebar,
    BForm,
    ToastificationContent,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  // model: {
  //   prop: "isAddNewUserSidebarActive",
  //   event: "update:is-add-new-user-sidebar-active",
  // },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    };
  },
  setup(props, { emit }) {
    const resetuserData = () => {};

    const getData = () => {
      store
        .dispatch("candidate/fetchSkills", {})
        .then((response) => {})
        .catch((error) => {
          for (const [key, value] of Object.entries(
            error.response.data.errors
          )) {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: "error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: value,
              },
            });
          }
        });
    };

    const onSubmit = () => {
      if (props.isEdit) {
        store
          .dispatch("candidate/SkillEdit", props.userData)
          .then(() => {
            emit("refetch-data");
            emit("update:is-add-new-user-sidebar-active", false);
            getData();
          })
          .catch((error) => {
            for (const [key, value] of Object.entries(
              error.response.data.errors
            )) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "error",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: value,
                },
              });
            }
          });
          props.isEdit = false;
          props.userData.skillSetName= "";
          props.userData.displayOrder= 0;
      } else {
        store
          .dispatch("candidate/addSkills", props.userData)
          .then(() => {
            emit("refetch-data");
            emit("update:is-add-new-user-sidebar-active", false);
            getData();
          })
          .catch((error) => {
            for (const [key, value] of Object.entries(
              error.response.data.errors
            )) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "error",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: value,
                },
              });
            }
          });
      }
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      onSubmit,
      getData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
