import axios from '@axios'

export default {
  namespaced: true,
  state: {
    candidate:null,
    skills:[]
  },
  getters: {},
  mutations: {
    UPDATE_CANDIDATE(state, candidate) {
      state.candidate = candidate
    },
    UPDATE_SKILLS(state, skills) {
      state.skills = skills
    },
  },
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => { //store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
        axios
          .post(`${process.env.VUE_APP_BASE_URL}/api/Candidate`, queryParams)
          .then(response =>  resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BASE_URL}/api/Candidate/${id}`)
          .then(response => {ctx.commit("UPDATE_CANDIDATE",response.data.data),resolve(response)})
          .catch(error => reject(error))
      })
    },
    editUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_BASE_URL}/api/Candidate`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSkills(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BASE_URL}/api/Skillset`, {
            pageSize: 100,
            currentPage: 1,
            sortExpression: null,
            sortDirection: null,
            includeDeletedRecords: false,
            searchName: null
          })
          .then(response =>{ctx.commit("UPDATE_SKILLS",response.data.data.items), resolve(response)})
          .catch(error => reject(error))
      })
    },
    addSkills(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BASE_URL}/api/Skillset/create`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPdf(ctx, { url }) {
      return new Promise((resolve, reject) => {
        axios
          .get(url, {
            responseType: "blob"
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPhoto(ctx,  {id,base64}) {
      var FormData = require('form-data');
      var data = new FormData();
      data.append('', base64);
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BASE_URL}/api/Candidate/update-avatar/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delete(ctx,{id}) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_BASE_URL}/api/Candidate/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePic(ctx,{id}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BASE_URL}/api/Candidate/remove-avatar/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    comment(ctx,  {id,data}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BASE_URL}/api/Candidate/${id}/comment`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }, ///api/Candidate/comment/{id}
    commentEdit(ctx,  {id,data}) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_BASE_URL}/api/Candidate/${id}/comment`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    commentDelete(ctx,  {id}) {
      return new Promise((resolve, reject) => {
        axios
         .delete(`${process.env.VUE_APP_BASE_URL}/api/Candidate/comment/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    SkillDelete(ctx,{id}) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_BASE_URL}/api/Skillset/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }, 
    SkillEdit(ctx,data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_BASE_URL}/api/Skillset`,data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCv(ctx,  {id,base64}) {
      var FormData = require('form-data');
      var data = new FormData();
      data.append('', base64);
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BASE_URL}/api/Candidate/update-cv/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addInterview(ctx, interviewData) {
      return new Promise((resolve, reject) => {
          axios
              .post(`${process.env.VUE_APP_BASE_URL}/api/Interview/createByCandidateList`, interviewData)
              .then(response => resolve(response))
              .catch(error => reject(error))
      })
  },
  fetchCheckCandidate(ctx, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/api/Interview/chakeCandidate/${id}`)
        .then(response => {resolve(response)})
        .catch(error => reject(error))
    })
  },

  },
}

